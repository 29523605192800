<template>
  <div class="Awarp">
    <dashCard class="photovoltaicBox11" :operateWidth="operateWidth" :isLoading="isLoading" :gridOption="gridOption" @changeScreen="changeScreen">
      <template slot="title">{{commonLangHandler('chuneng5_overView','电池信息总览', getZEdata)}}</template>
      <div class="card-content1" style="margin-top: 50px;">
        <div class="product-info">
          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>SOC</span>
              <span>{{detailInfo["SOC"]}}%</span>
            </div>
            <div class="product-info-item-progress">
              <span :style="{width: `${Number(detailInfo['SOC']) > 100 ? 100 : detailInfo['SOC'] }%`}"></span>
            </div>
          </div>

          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>SOH</span>
              <span>{{detailInfo["SOH"]}}%</span>
            </div>
            <div class="product-info-item-progress">
              <span :style="{width : `${Number(detailInfo['SOH']) > 100 ? 100 : detailInfo['SOH']}%`}"></span>
            </div>
          </div>

          <div class="product-info-item">
            <div class="product-info-item-top">
              <span>DOD</span>
              <span>{{detailInfo["DOD"]}}%</span>
            </div>
            <div class="product-info-item-progress">
              <span :style="{width: `${Number(detailInfo['DOD'])  > 100 ? 100 : detailInfo['DOD'] }%`}"></span>
            </div>
          </div>
        </div>
      </div>
      <a-descriptions bordered>
        <a-descriptions-item v-for="(item,idx) in columns" :key="idx" :label="item.title" :span="3">
          <span v-if="!!item.value1">{{ detailInfo[item.value1] }}</span>
          <span v-if="!!item.value2"> {{ detailInfo[item.value2] }}</span>
        </a-descriptions-item>
      </a-descriptions>
    </dashCard>

    <a-modal v-if="!isModal" class="Amodal" v-model="option.visible" :closable="false" :footer="null" :keyboard="false" width="100%" :dialog-style="{ top: '0px' }">
      <div style="height:calc(100vh)">
        <chuneng5  :option="option" :isModal="true" :orginSearchObj="searchObj"/>
      </div>
    </a-modal>
  </div>
</template>
<script>
import Img005 from "@/assets/imgs/add/product5.png"
import Img001 from "@/assets/imgs/add/product1.png";
export default {
  name: 'chuneng5',
  components: {
    dashCard: () => import('@/component/dashCard.vue'),
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false,//数据加载中
      columns: [
        { title: this.LangHandler('chuneng5cycle','电池累计循环次数'), value1: 'value2',value2: 'value3'},
        { title: this.LangHandler('chuneng5temp','最高温度'), value1: 'value4',value2: 'value5'},
      ],
      itemList: [
        {
          code: '逆变器编号：1AA1',
          name:'逆变器编号：1AA1'
        },
      ],
      searchObj: {
        ITEM: '',//选中的项目
      },
      list1:{},
      chartOption1: {},
      detailInfo: {},
      colorList: ["#1191E6","#7ED6C6","#1E6119","#160C9C"],
      optionsData:[],
    }
  },
  props: {
    gridOption: {
      type: Object,
      default: function() {
        return { w: 6, h: 6 }
      }
    },
    option: {
      type: Object,
      default: function() {
        return {
          visible: false
        }
      }
    },
    isModal: {
      type: Boolean,
      default: function() {
        return false
      }
    },
    orginSearchObj: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  watch: {
    gridOption: {
      handler: function(newVal,oldVal) {
        this.$nextTick(_=>{
          this.getOperateWidth()
          var dom = this.$refs['chart1']
          if(!!dom) {
            dom.handleWindowResize()
          }
        })
      },
      deep: true,
      immediate: true
    },
    "option.visible": {//全屏化时执行
      handler: function(newVal,oldVal) {
        if(newVal) {
          if(this.isModal) {
            this.searchObj = this.orginSearchObj
            this.getData()
          }
        }
      },
      deep: true,
      immediate: true
    },
    "currentSelectDeptInfo.CODE": {
      handler: function(newVal,oldVal) {
        if(oldVal) {
          //this.getList()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo
    }
  },
  methods: {
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE
      var dateStr = this.searchObj.VALUE.format('YYYY-MM-DD')
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf('month');
      var obj = {
        "按天": {
          type: "今日",
          startDate: VALUE.format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.format('YYYY-MM-DD 23:59:59'),
        },
        "按周": {
          type: "本周",
          startDate: VALUE.startOf('week').format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.endOf('week').format('YYYY-MM-DD 23:59:59'),
        },
        "按月": {
          type: "本月",
          startDate: monthVALUE.startOf('month').format('YYYY-MM-DD 00:00:00'),
          endDate: monthVALUE.endOf('month').format('YYYY-MM-DD 23:59:59'),
        },
        "按年": {
          type: "全年",
          startDate: VALUE.startOf('year').format('YYYY-MM-DD 00:00:00'),
          endDate: VALUE.endOf('year').format('YYYY-MM-DD 23:59:59'),
        }
      }
      return obj[TYPE]
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal
      if(!this.option.visible) {
        this.getData()
      }
    },
    // 右侧操作列宽度
    getOperateWidth () {
      if(!this.$el.querySelector) {
        return
      }
      var dom = this.$el.querySelector(".card-head-operate")
      if(!!dom) {
        var width = dom.clientWidth
        this.operateWidth = width > 0 ? width + 12 : width
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function(value) {
      if(!value) return 0
      // 获取整数部分
      const intPart = Math.trunc(value)
      // 整数部分处理，增加,
      const intPartFormat = intPart.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      // 预定义小数部分
      let floatPart = ''
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split('.')
      if (valueArray.length === 2) { // 有小数部分
        floatPart = valueArray[1].toString() // 取得小数部分
        return intPartFormat + '.' + floatPart
      }
      return intPartFormat + floatPart
    },

    //每月报警预警数
    getData() {
      this.isLoading = true
      this.$axios({
        url:Config.dev_url + "/api-apps-v2/api/v2/battery/info",
        method:"post",
        data:{
          equipment: this.currentSelectDeptInfo.CODE
        }
      }).then(res=>{
        this.detailInfo = {
          value2:res.data.data["cyclesNumber"],
          value3:this.LangHandler('times','次'),
          value4:res.data.data["maxTemperature"],
          value5:"℃",
          ...res.data.data,
        }
        //console.log(this.detailInfo)
        this.isLoading = false

      }).catch((wrong)=>{ }).finally(()=>{
        this.isLoading = false
      })

    },

  },
  mounted() {
    this.getOperateWidth()
    if(!this.option.visible) {
      this.getData()
    }
  }
}
</script>

<style lang="less" scoped>
.product-info {
  flex: 1;

  .product-info-item {
    margin-bottom: 60px;

    .product-info-item-top {
      display: flex;
      justify-content: space-between;

      font-size: 16px;
      font-weight: 500;
      color: #1f1f1f;
      line-height: 20px;
    }

    .product-info-item-progress {
      width: 100%;
      height: 8px;
      background: #dbdbdb;
      margin-top: 14px;
      margin-bottom: 9px;

      span {
        display: block;
        height: inherit;
        width: 0%;
        background: #51A34B;
      }
    }

    .product-info-item-bottom {
      display: flex;
      justify-content: space-between;

      font-size: 14px;
      font-weight: 400;
      color: #696969;
      line-height: 16px;
    }
  }

}
/deep/.ant-descriptions-bordered {
  font-weight: bold;
  .ant-descriptions-item-label {
    background: transparent;
    width: 362px;
  }
  .ant-descriptions-item-content {
    background: #F5F5F5;
    display: flex;
    justify-content: space-between;
  }
}
/deep/ .ant-descriptions-item-label{
  font-weight: bold !important;
}
</style>
